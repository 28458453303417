import { useContext } from "react"
import { Link } from "react-router-dom"
import AuthContext from "../Auth/AuthContext"

export default function Home() {

    const context = useContext(AuthContext)

    return (
        <div className="main">
            <h1>Hello {context?.auth?.displayName?.charAt(0).toUpperCase() + context?.auth?.displayName?.slice(1)}!</h1>
            <div className="menu-holder" >
                <div className="home-menu-item">
                    <Link to="/view"> Tervek megtekintése </Link>
                </div>
            </div>
        </div>
    )
}