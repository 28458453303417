import { Tooltip } from '@material-ui/core'
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'

import ImageIcon from '@material-ui/icons/Image'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Link } from 'react-router-dom'


export default function Sidebar() {

    return (
        <ProSidebar
            collapsed={true}
        >
            <SidebarHeader>
                <Link to='/'>
                    <img className='logo' src='https://smilezor.hu/wp-content/uploads/2021/05/cropped-smilezorfav-1-192x192.png' alt='logo' />
                </Link>
            </SidebarHeader>
            <SidebarContent>
                <Menu iconShape='circle'>
                    <Tooltip title='Tervek'>
                        <MenuItem icon={<ImageIcon color='primary' />}>
                            <Link to='/view'>Tervek</Link>
                        </MenuItem>
                    </Tooltip>
                </Menu>
            </SidebarContent>
            <SidebarFooter>
                <Menu iconShape='circle'>
                    <Tooltip title='Kijelentkezés'>
                        <MenuItem icon={<ExitToAppIcon color='primary' />}>
                            <Link to='/logout'>Kijelentkezés</Link>
                        </MenuItem>
                    </Tooltip>
                </Menu>
            </SidebarFooter>
        </ProSidebar>
    )
}