// export const API_URL = 'http://localhost:80/wp-json/'
// export const IMAGE_URL = 'http://localhost:80/wp-content/uploads/smd/'

export const API_URL = 'https://smilezor.hu/wp-json/'
export const IMAGE_URL = 'https://smilezor.hu/wp-content/uploads/smd/'

export const LOGO_URL = 'https://smilezor.hu/wp-content/uploads/2021/05/smilezorfav-1.png'
export const VERSION = '0.1.21-alpha'
export const TOKEN = 'SMD-AUTH'
export const A_SHIFT = [
    {
        value: 'bal',
        label: 'Balra tolva',
    },
    {
        value: 'közép',
        label: 'Középre tolva',
    },
    {
        value: 'jobb',
        label: 'Jobbra tolva',
    }
]
export const A_METHODS = [
    {
        value: 'javít',
        label: 'Javít',
    },
    {
        value: 'idealizál',
        label: 'Idealizál',
    },
    {
        value: 'fentart',
        label: 'Fentart',
    }
]