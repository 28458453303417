import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Paper, Button, Tab, Tabs } from '@material-ui/core'

import ImagePreview from '../Components/ImagePreview'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useApi from '../API/API'
import useAuthService from '../Auth/AuthService'

export default function View(props) {

    const [medical, setMedical] = useState(props?.location?.state?.record)
    const [redirect, setRedirect] = useState(false)
    const [tab, setTab] = useState(0)

    const API = useApi()
    const authService = useAuthService()

    useEffect(() => {
        let id = authService.getCurrentUser()['id']
        API.get(`smd/v1/client?ID=${id}`)
            .then(res => {
                setMedical(res.data)
            })
    }, [])

    const goBack = () => {
        setRedirect({
            pathname: "/",
            state: { from: props.location }
        })
    }

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }

    return (
        <>
            {redirect &&
                <Redirect to={redirect} />
            }
            <Paper
                className='scrollbox_holder'
                square >
                <div className="col">
                    <Button style={{margin: '10px'}} ><ArrowBackIcon onClick={goBack} /></Button>
                    <Tabs
                        style={{ margin: 'auto' }}
                        value={tab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Képek" />
                    </Tabs>
                </div>
                {tab === 0 &&
                    <ImagePreview
                        medical={medical}
                    />
                }
            </Paper>
        </>
    )
}